import React from "react";
import { Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles
const Abouttitle = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: 15px 0 0 0;
  font-size: 2.3rem;
`

const Aboutsubtitle = styled.h2`
  color: ${({ theme }) => theme.colours.primary.main};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 1.5rem;
`


const Aboutcontent = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  
  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
  
  @media (min-width: 992px) { 
    margin-bottom: 50px;
  }
`

const Aboutimg = styled(GatsbyImage)`
  margin: 0 auto;
  z-index: -1;
  height: 100%;
  width: 100%;
`

const Aboutrow = styled(Row)`

  @media (min-width: 992px) { 
    margin-bottom: 50px;
  }
`

const Imgcol = styled(Col)`
  // background: ${({ theme }) => theme.animations.imgtint};
`

const Aboutcontainer = styled(Col)`
  background-color: ${({ theme }) => theme.colours.common.white};
  margin-top: 0px;
  padding: 25px;

  @media (min-width: 992px) { 
    margin-top: 25px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
  }

`

function Aboutlist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      concut_about(filter: {status: {_eq: "Published"}}) {
        title
        subtitle
        content
        about_image {
          id
          title
           imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
  
  `)

  const aboutNode = data.directus.concut_about;
 
  return (
    
    <>
    
        {aboutNode.map((aboutItem, i) => 
        (
          <Aboutrow key={i}>
{(() => {
        if (i % 2 === 0) {
          return (
          <>
            <Imgcol xs={12} lg={6} className="px-0">
              <Aboutimg image={aboutItem.about_image.imageFile.childImageSharp.gatsbyImageData} alt={aboutItem.title} objectPosition="center center" />
            </Imgcol>
            <Aboutcontainer xs={12} lg={6}>
              <Abouttitle>{aboutItem.title}</Abouttitle>
              <Aboutsubtitle>{aboutItem.subtitle}</Aboutsubtitle>
              <Aboutcontent dangerouslySetInnerHTML={{
                    __html: aboutItem.content,
                }} />
            </Aboutcontainer>
          </>
          )
        } else {
          return (
            <>
            <Imgcol xs={12} lg={6} className="px-0 d-sm-block d-lg-none">
              <Aboutimg image={aboutItem.about_image.imageFile.childImageSharp.gatsbyImageData} alt={aboutItem.title} objectPosition="center center" />
            </Imgcol>
            <Aboutcontainer xs={12} lg={6}>
              <Abouttitle>{aboutItem.title}</Abouttitle>
              <Aboutsubtitle>{aboutItem.subtitle}</Aboutsubtitle>
              <Aboutcontent dangerouslySetInnerHTML={{
                    __html: aboutItem.content,
                }} />
            </Aboutcontainer>
            <Imgcol xs={12} lg={6} className="px-0 d-none d-lg-block">
              <Aboutimg image={aboutItem.about_image.imageFile.childImageSharp.gatsbyImageData} alt={aboutItem.title} objectPosition="center center" />
            </Imgcol>
            </>
          )
        }
      })()}
            </Aboutrow>
             ))}        
    
  </>
  );
}

export default Aboutlist;